import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  font-size: ${props => props.theme.fonts.headerSize};
  left: ${props => props.theme.geometries.paddingPage};
  position: fixed;
  top: ${props => props.theme.geometries.paddingPage};
  z-index: 100;
`

const Header = styled(Link)`
  color: ${props => props.theme.colors.textDark};
  display: block;
  padding-bottom: 0.3rem;
  text-decoration: none;
  text-transform: lowercase;
`

const SubHeader = styled.p`
  border-bottom: 1px solid ${props => props.theme.colors.textLight};
  color: ${props => props.theme.colors.textLight};
  display: inline-block;
  margin: 0;
  padding: 0 0 0.2rem 0;
  text-transform: lowercase;
  white-space: nowrap;
`

export default class Nav extends Component {
  render() {
    const { error, loading, notFound, subHeader } = this.props

    return (
      <Container>
        <Header to='/'>huyen do</Header>
        <SubHeader>
          {loading && 'Loading...'}
          {notFound && 'Page Not Found'}
          {error && 'Error'}
          {!loading && !error && !notFound && subHeader}
        </SubHeader>
      </Container>
    )
  }
}