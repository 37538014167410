import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { MEDIA, DESKTOP_IMG_WIDTH } from '../logic/theme'

const Container = styled.div`
  background: ${props => props.theme.colors.backgroundThumbs};
  height: 100vh;
  left: 0;
  opacity: ${props => props.open ? '1' : '0'};
  overflow: scroll;
  padding: ${props => props.theme.geometries.paddingPage};
  padding-top: calc(${props => props.theme.geometries.paddingPage} + ${props => props.theme.geometries.paddingContentLoose});
  position: fixed;
  top: ${props => props.open ? '0' : '100vh'};
  transition: top 0.2s ease-in, opacity 0.2s ease-in;
  width: 100vw;
  -webkit-overflow-scrolling: touch;
  z-index: 200;
`

const Link = styled.button`
  background: none;
  border: none;
  border-bottom: ${props => props.open ? '1px solid ' + props.theme.colors.textInfo : 'none'};
  color: ${props => props.theme.colors.textDark};
  cursor: pointer;
  font-size: ${props => props.theme.fonts.headerSize};
  margin: 0;
  outline: none;
  padding: 0;
  position: fixed;
  right: ${props => props.theme.geometries.paddingPage};
  top: ${props => props.theme.geometries.paddingPage};
  z-index: 100;
`

const Thumb = styled.div`
  cursor: pointer;
  float: left;
  padding-top: calc(${props => props.theme.geometries.paddingPage} / 2);
  
  ${MEDIA.phone`
    padding-bottom: ${props => props.theme.geometries.paddingPage};
    width: 100%;
  `}
  
  ${MEDIA.desktop`
    height: 25vw;
    width: 33.3%;
  `}
  
  & > img {
    height: 100%;
    object-fit: contain;
    padding-left: calc(${props => props.theme.geometries.paddingPage} / 4);
    padding-right: calc(${props => props.theme.geometries.paddingPage} / 4);
    width: 100%;
  }
`

export default class Thumbs extends Component {
  constructor(props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentWillMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.props.onClick()
    }
  }

  render() {
    const { gallery, onClick, onThumbClick, open } = this.props

    return (
      <Fragment>
        <Link onClick={onClick} open={open}>thumbs</Link>
        <Container open={open}>
          {gallery.fields.slides.map((s, i) =>
            s.sys.contentType.sys.id === 'slide' && s.fields.photos.map(p =>
              <Thumb key={p.sys.id}>
                <img alt={p.fields.caption} key={p.sys.id} onClick={e => { onThumbClick(i) }} src={p.fields.file.url + `?w=${DESKTOP_IMG_WIDTH}&h=${DESKTOP_IMG_WIDTH}`}/>
              </Thumb>
            )
          )}

        </Container>
      </Fragment>
    )
  }
}