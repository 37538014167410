import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { MEDIA } from '../logic/theme'

const Link = styled.button`
  background: none;
  border: none;
  border-bottom: ${props => props.open ? '1px solid ' + props.theme.colors.textInfo : 'none'};
  color: ${props => props.open ? props.theme.colors.textInfo : props.theme.colors.textDark};
  cursor: pointer;
  font-size: ${props => props.theme.fonts.headerSize};
  margin: 0;
  outline: none;
  padding: 0;
  position: fixed;
  right: ${props => props.theme.geometries.paddingPage};
  top: ${props => props.theme.geometries.paddingPage};
  z-index: 300;
`

const Container = styled.div`
  background-color: ${props => props.theme.colors.backgroundInfo};
  color: ${props => props.theme.colors.textInfo}; 
  font-size: ${props => props.theme.fonts.headerSize};
  height: 100vh;
  left: 0;
  padding: ${props => props.theme.geometries.paddingPage};
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;
  
  & > p {
    padding: 0 0 2rem 0;
    text-transform: lowercase;
    margin: 0;
  }
  
  & > p > em {
    display: block;
    font-style: normal;
    margin: 0;
    padding: 0 0 0.5rem 0;
  }
  
  & > p > a {
    color: ${props => props.theme.colors.textInfo}; 
    display: block;
    margin: 0;
    padding: 0 0 0.35rem 0;
    text-decoration: none;
  }
  
  ${MEDIA.phone`
    & > p:first-child {
      padding-top: 12vw !important;
    }
  `}
`

export default class Info extends Component {
  render() {
    const { onClick, open, section } = this.props

    document.getElementsByTagName('html')[0].className = open ? 'overlay' : null

    return (
        <Fragment>
          <Link onClick={onClick} open={open}>info</Link>
          {open &&
            <Container>
              <p>{section.fields.bio}</p>
              <p>
                <em>studio:</em>
                <a href={'mailto:'+section.fields.email}>{section.fields.email}</a>
                <a href={'tel:'+section.fields.phone}>{section.fields.phone}</a>
              </p>
              {section.fields.social.length > 0 &&
                <p>
                  <em>social:</em>
                  {section.fields.social.map(s =>
                    <a href={s.fields.url} key={s.sys.id}>{s.fields.label}</a>
                  )}
                </p>
              }
            </Container>
          }
        </Fragment>
    )
  }
}