import { css } from 'styled-components'

export function initTheme() {
  return {
    colors: {
      backgroundInfo: '#acacac',
      backgroundPage: '#fffbf7',
      backgroundThumbs: 'rgba(255, 251, 247, 0.9)',
      textDark: '#4b4948',
      textInfo: '#ffffff',
      textLight: '#909090'
    },
    fonts: {
      headerSize: '1.3rem',
      pageSize: '1.1rem'
    },
    geometries: {
      paddingContentLoose: '6rem',
      paddingContentTight: '4.5rem',
      paddingPage: '6vw',
      maxPhoneWidth: '599px',
      minDesktopWidth: '600px'
    },
  }
}

export const MOBILE_IMG_WIDTH = 600
export const DESKTOP_IMG_WIDTH = 2500

export const MEDIA = {
  phone: (...args) => css`
    @media (max-width: ${props => props.theme.geometries.maxPhoneWidth}) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (min-width: ${props => props.theme.geometries.minDesktopWidth}) {
      ${css(...args)}
    }
  `,
}
