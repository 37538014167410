import { createClient } from 'contentful'

const target = {
  space: 'lzgo4qb064ub',
  accessToken: 'e1d12bd05c1daee6b673608c1f375305290ab1fb926a19e5d9ae55ea219685b3',
  site: {
    design: {
      section: '5L8GBeGpaFa0NONzw8QiqB'
    },
    photography: {
      section: '11pOTdaDwt2zpWES9s6bOJ'
    }
  }
}

const client = createClient({
  space: target.space,
  accessToken: target.accessToken
})

export async function getSection() {
  return client.getEntry(target.site[process.env.REACT_APP_TARGET].section)
}

export async function getGallery(slug) {
  const resp = await client.getEntries({
    'content_type': 'gallery',
    'fields.slug': slug,
    'include': 2
  })
  if (resp.total === 0) {
    return null
  }
  if (resp.total !== 1) {
    throw new Error('Unexpected number of results.')
  }

  return resp.items[0]
}