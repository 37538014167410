import React, { Component } from 'react'
import styled from 'styled-components'
import { MEDIA } from '../logic/theme'

const Container = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.textDark};
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  
  ${MEDIA.phone`
    margin-bottom: 2rem;
  `}
  
  ${MEDIA.desktop`
    display: ${props => props.visible ? 'flex' : 'none'};
    height: 100%;

    & > p {
      max-width: 30rem;
    }
  `}
  
  & > p {
    margin: 0;
    padding: 0;
    text-transform: lowercase;
`

export default class Intermission extends Component {
  static parapgraphize(content) {
    return content.split("\n").map((p, i) =>
        p ? <p key={i}>{p}</p> : <br key={i}/>)
  }

  render() {
    const { intermission, onClick, visible } = this.props

    return (
        <Container onClick={onClick} visible={visible}>{Intermission.parapgraphize(intermission.fields.content)}</Container>
    )
  }
}