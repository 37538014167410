import React, { Component } from 'react'
import styled from 'styled-components'
import { MEDIA } from '../logic/theme'

const Container = styled.p`
  color: ${props => props.theme.colors.textDark};
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: lowercase;
  
  ${MEDIA.phone`
    padding-bottom: 2rem;
  `}
  
  ${MEDIA.desktop`
    bottom: 3vw;
    left: 0;
    position: fixed;
    width: 100vw;
  `}
`

export default class Caption extends Component {
  render() {
    const { caption } = this.props

    return (
        <Container>{caption}</Container>
    )
  }
}