import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MEDIA, DESKTOP_IMG_WIDTH, MOBILE_IMG_WIDTH } from '../logic/theme'

const Figure = styled.figure`
  align-self: ${props => props.gallery.fields.alignment === 'Left' ? 'flex-start' : 'flex-end'};
  margin: 0;
  padding: 0 0 ${props => props.theme.geometries.paddingContentLoose} 0;
  ${MEDIA.phone`width: 100%;`}
  ${MEDIA.desktop`width: ${props => props.gallery.fields.width};`}
 
  
  & > a > img {
    height: auto;
    width: 100%;
  }
  
  & > figcaption {
    color: ${props => props.theme.colors.textDark};
    font-size: ${props => props.theme.fonts.pageSize};
    padding-top: 0.4rem;
    text-transform: lowercase;
  }
`

export default class Cover extends Component {
  constructor(props) {
    super(props)
    this.getImageSize = this.getImageSize.bind(this)
  }

  getImageSize() {
    return this.props.mode === 'desktop'
        ? `?w=${DESKTOP_IMG_WIDTH}&h=${DESKTOP_IMG_WIDTH}`
        : `?w=${MOBILE_IMG_WIDTH}&h=${MOBILE_IMG_WIDTH}`
  }

  render() {
    const { gallery } = this.props

    return (
      <Figure gallery={gallery}>
        <Link to={'/' + gallery.fields.slug}>
          <img alt={gallery.fields.title} src={gallery.fields.cover.fields.file.url + this.getImageSize()}/>
        </Link>
        <figcaption>{gallery.fields.title}</figcaption>
      </Figure>
    )
  }
}