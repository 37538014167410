import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Caption from './Caption'
import { MEDIA, MOBILE_IMG_WIDTH, DESKTOP_IMG_WIDTH } from '../logic/theme'

const Container = styled.div`
  ${MEDIA.phone`
    display: flex;
    flex-flow: column nowrap;
   
    & > img {
      height: auto;
      width: 100%;
      margin-bottom: 2rem;
    }
  `}

  ${MEDIA.desktop`
    align-items: center;
    cursor: pointer;
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-flow: row nowrap;
    height: 100%;
    justify-content: space-between;
    width: 100%;
    
    & > img {
      object-fit: contain;
      height: 100%;
      width: calc(100% / ${props => props.count} - ${props => props.theme.geometries.paddingPage} * (${props => props.count} - 1) / ${props => props.count});
    }
  `}
`

export default class Slide extends Component {
  constructor(props) {
    super(props)
    this.getImageSize = this.getImageSize.bind(this)
  }

  getImageSize() {
    return this.props.mode === 'desktop'
      ? `?w=${DESKTOP_IMG_WIDTH}&h=${DESKTOP_IMG_WIDTH}`
      : `?w=${MOBILE_IMG_WIDTH}&h=${MOBILE_IMG_WIDTH}`
  }

  render() {
    const { onClick, slide, visible } = this.props

    return (
      <Fragment>
        <Container count={slide.fields.photos.length} onClick={onClick} visible={visible}>
          {slide.fields.photos.map(p =>
            <img alt={slide.fields.caption} key={p.sys.id} src={p.fields.file.url + this.getImageSize()}/>
          )}
        </Container>
        {visible && slide.fields.caption && <Caption caption={slide.fields.caption}/>}
      </Fragment>
    )
  }
}