import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import Intermission from '../components/Intermission'
import Nav from '../components/Nav'
import Slide from '../components/Slide'
import Thumbs from '../components/Thumbs'
import { getGallery } from '../logic/network'
import { MEDIA } from '../logic/theme'

const Container = styled.div`
  display: block;
  height: 100vh;
  width: 100vw;
  padding: ${props => props.theme.geometries.paddingPage};
  ${MEDIA.phone`padding-top: calc(${props => props.theme.geometries.paddingPage} + ${props => props.theme.geometries.paddingContentLoose});`}
  ${MEDIA.desktop`padding-top: calc(${props => props.theme.geometries.paddingPage} + ${props => props.theme.geometries.paddingContentTight});`}
`

export default class Gallery extends Component {
  constructor(props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleSlideClick = this.handleSlideClick.bind(this)
    this.handleThumbClick = this.handleThumbClick.bind(this)
    this.handleThumbsClick = this.handleThumbsClick.bind(this)
    this.state = {
      currentSlide: 0,
      error: false,
      gallery: null,
      loading: true,
      thumbsOpen: false
    }
  }

  componentWillMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  async loadData() {
    try {
      this.setState(update(this.state, {
        error: { $set: false },
        gallery: { $set: await getGallery(this.props.match.params.slug) },
        loading: { $set: false }
      }))
    } catch (err) {
      console.error(err)
      this.setState(update(this.state, {
        error: { $set: true },
        gallery: { $set: null },
        loading: { $set: false }
      }))
    }
  }

  handleKeyDown(event) {
    const { currentSlide, gallery, thumbsOpen } = this.state

    if (thumbsOpen) {
      return
    }

    if (event.key === 'ArrowRight') {
      return this.setState(update(this.state, {
        currentSlide: { $set: (currentSlide + 1) % gallery.fields.slides.length }
      }))
    }

    if (event.key === 'ArrowLeft') {
      return this.setState(update(this.state, {
        currentSlide: { $set: (currentSlide - 1) < 0
          ? gallery.fields.slides.length - 1
          : (currentSlide - 1) % gallery.fields.slides.length
        }
      }))
    }
  }

  handleSlideClick() {
    const { currentSlide, gallery } = this.state

    if (gallery) {
      this.setState(update(this.state, {
        currentSlide: { $set: (currentSlide + 1) % gallery.fields.slides.length }
      }))
    }
  }

  handleThumbClick(slide) {
    this.setState(update(this.state, {
      currentSlide: { $set: slide },
      thumbsOpen: { $set: false }
    }))
  }

  handleThumbsClick() {
    this.setState(update(this.state, {
      thumbsOpen: { $set: !this.state.thumbsOpen }
    }))
  }

  render() {
    const { mode } = this.props
    const { currentSlide, gallery, error, loading, thumbsOpen } = this.state

    return (
      <Fragment>
        <Nav error={error} loading={loading} notFound={!error && !loading && !gallery} subHeader={gallery && gallery.fields.title}/>
        {gallery && mode === 'desktop' && <Thumbs gallery={gallery} onClick={this.handleThumbsClick} onThumbClick={this.handleThumbClick} open={thumbsOpen}/>}
        {gallery &&
          <Container>
            {gallery.fields.slides.map((s, i) =>
              s.sys.contentType.sys.id === 'slide'
                ? <Slide key={s.sys.id} mode={mode} onClick={this.handleSlideClick} slide={s} visible={i === currentSlide}/>
                : <Intermission intermission={s} onClick={this.handleSlideClick} key={s.sys.id} mode={mode} visible={i === currentSlide}/>
            )}
          </Container>
        }
      </Fragment>
    )
  }
}
