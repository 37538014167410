import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import Cover from '../components/Cover'
import Info from '../components/Info'
import Nav from '../components/Nav'
import { getSection } from '../logic/network'
import { MEDIA } from '../logic/theme'

const Container = styled.div`
  display: flex;
  padding: ${props => props.theme.geometries.paddingPage};
  padding-top: calc(${props => props.theme.geometries.paddingPage} + ${props => props.theme.geometries.paddingContentLoose});
  ${MEDIA.phone`flex-flow: column nowrap;`}
  ${MEDIA.desktop`flex-flow: row nowrap;`}
`

const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${MEDIA.phone`
    width: 100%;
    
    &:last-child() {
      margin-bottom: -${props => props.theme.geometries.paddingContentLoose};
    }
  `}
  
  ${MEDIA.desktop`
    margin-bottom: -${props => props.theme.geometries.paddingContentLoose};
    width: 45%;
  `}  
`

const Gutter = styled.div`
  ${MEDIA.phone`display: none;`}
  ${MEDIA.desktop`width: 10%`}
`

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.handleInfoClick = this.handleInfoClick.bind(this)
    this.state = {
      section: null,
      error: false,
      infoOpen: false,
      loading: true,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    try {
      this.setState(update(this.state, {
        section: { $set: await getSection() },
        error: { $set: false },
        loading: { $set: false }
      }))
    } catch (err) {
      console.error(err)
      this.setState(update(this.state, {
        section: { $set: null },
        error: { $set: true },
        loading: { $set: false }
      }))
    }
  }

  handleInfoClick() {
    this.setState(update(this.state, {
      infoOpen: { $set: !this.state.infoOpen }
    }))
  }

  render() {
    const { mode } = this.props
    const { section, error, infoOpen, loading } = this.state

    return (
      <Fragment>
        <Nav error={error} loading={loading} subHeader={section ? section.fields.profession : ''}/>
        <Info onClick={this.handleInfoClick} open={infoOpen} section={section}/>
        {section && !infoOpen &&
          <Container>
            <Column>
              {section.fields.galleries.filter(g => g.fields.side === 'Left').map(g =>
                <Cover gallery={g} key={g.sys.id} mode={mode}/>
              )}
            </Column>
            <Gutter/>
            <Column>
              {section.fields.galleries.filter(g => g.fields.side === 'Right').map(g =>
                <Cover gallery={g} key={g.sys.id} mode={mode}/>
              )}
            </Column>
          </Container>
        }
      </Fragment>
    )
  }
}