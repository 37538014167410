import 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider} from 'styled-components'
import App from './App'
import './index.css'
import { initTheme } from './logic/theme'

ReactDOM.render(
    <ThemeProvider theme={initTheme}>
      <App/>
    </ThemeProvider>,
    document.getElementById('root'));