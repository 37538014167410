import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Gallery from './containers/Gallery'
import Home from './containers/Home'
import update from "react-addons-update";

class App extends Component {
  static getMode(minDesktopWidth) {
    return window.innerWidth >= parseInt(minDesktopWidth)
        ? 'desktop'
        : 'phone'
  }

  constructor(props) {
    super(props)
    this.handleResize = this.handleResize.bind(this)
    this.state = {
      mode: App.getMode(this.props.theme.geometries.minDesktopWidth)
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    const { mode } = this.state
    const newMode = App.getMode(this.props.theme.geometries.minDesktopWidth)

    if (mode !== newMode) {
      this.setState(update(this.state, {
        mode: { $set: newMode },
      }))
    }
  }

  render() {
    const { mode } = this.state

    return (
      <Router>
        <Switch>
          <Route exact path='/' render={(props) => <Home {...props} mode={mode}/>}/>
          <Route exact path='/:slug([a-zA-Z0-9-]+)' render={(props) => <Gallery {...props} mode={mode}/>}/>
        </Switch>
      </Router>
    );
  }
}

export default withTheme(App)